import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgePipe } from './age.pipe';
import { NotAvailablePipe } from './na.pipe';
import { ConvertToClassPipe } from './convert-to-class.pipe';
import { SafeUrlPipe } from './safe-url.pipe';



@NgModule({
  declarations: [
    AgePipe,
    NotAvailablePipe,
    ConvertToClassPipe,
    SafeUrlPipe
  ],
  exports: [
    AgePipe,
    NotAvailablePipe,
    ConvertToClassPipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class HhPipesModule { }
