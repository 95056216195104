import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertToClass'
})
export class ConvertToClassPipe implements PipeTransform {

    transform(value: string): string {
        if (value) {
            return value.replace(' ', '-').toLocaleLowerCase();
        }

        return value;
    }

}