import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'na'
})
export class NotAvailablePipe implements PipeTransform {

    transform(value: any): any {
        if (!value) {
            return 'N/A';
        }

        return value;
    }

}